<template>
  <section>
    <div class="row m-0">
      <MenuTaller />
      <loading class="col-lg-10 p-0" v-if="estadoloading" />
      <div
        class="col-lg-10 p-0"
        style="height: calc(100vh - 113px); overflow-y: scroll"
        v-else
      >
        <div class="row m-0 justify-content-end">
          <div class="col-3 mt-3">
            <input
              type="search"
              placeholder="Buscar plantilla"
              class="form-control form-control-sm"
              v-model="search"
            />
          </div>
        </div>
        <div
          class="list-taller border-bottom border-right mt-2 mb-2 pt-2 pl-2"
          v-for="plantilla in plantillas.filter(
            (data) =>
              !search ||
              data.nombre.toLowerCase().includes(search.toLowerCase())
          )"
          :key="plantilla.id"
        >
          <p class="mb-0">
            <label class="font-weigth-bold">
              <strong>Nombre plantilla:</strong>
            </label>
            {{ plantilla.nombre }}
          </p>
          <div class="row">
            <div class="col-7">
              <div class="row" v-if="plantilla.metodologia == 0">
                <div class="col-2">
                  <strong>Cuerpo:</strong>
                  {{ plantilla.cuerpo }}
                </div>
                <div class="col-2">
                  <strong>Voz:</strong>
                  {{ plantilla.voz }}
                </div>
                <div class="col-2">
                  <strong>Forma:</strong>
                  {{ plantilla.forma }}
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="row justify-content-end mr-2">
                <div class="col-2 p-0 mx-1">
                  <p
                    class="btn-primary btn-sm text-center cursor-pointer"
                    @click="editar(plantilla.id)"
                  >
                    Editar
                  </p>
                </div>
                <div class="col-2 p-0 mx-1">
                  <p
                    class="btn-info btn-sm text-center cursor-pointer"
                    @click="openDuplicar(plantilla)"
                  >
                    Duplicar
                  </p>
                </div>
                <div class="col-2 p-0 mx-1">
                  <p
                    class="btn-danger btn-sm text-center cursor-pointer"
                    @click="openEliminar(plantilla.id)"
                  >
                    Eliminar
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-eliminar ref="modalEliminar" @eliminar="eliminar" />
    <modalDuplicar
      ref="modalDuplicar"
      :message="mensaje_duplicar"
      @duplicar="confirmar_duplicar"
    />
  </section>
</template>
<script>
import MenuTaller from "@/components/MenuTaller";
import loading from "@/components/Loading";
import modalDuplicar from "@/components/ModalDuplicar";
export default {
  name: "plantillas",
  data() {
    return {
      search: "",
      plantilla: "",
      mensaje_duplicar: "",
    };
  },
  components: {
    MenuTaller,
    loading,
    modalDuplicar,
  },
  mounted() {
    this.$store.dispatch("fetchPlantillas");
  },
  computed: {
    estadoloading() {
      return this.$store.getters.getEstatusLoading;
    },
    plantillas() {
      return this.$store.getters.getPlantillas;
    },
  },
  methods: {
    openModal(plantilla) {
      this.plantilla = plantilla;
      this.$refs.modalDuplicar.open();
    },
    duplicar_plantilla() {
      this.$refs.modalDuplicar.open();
    },
    openEliminar(id) {
      this.id_duplicar = id;
      this.$refs.modalEliminar.open();
    },
    editar(id) {
      this.$router.push({ name: "editar.plantilla", params: { id: id } });
    },
    openDuplicar(data) {
      this.id_duplicar = data.id;
      this.mensaje_duplicar = `¿Seguro que quieres duplicar la plantilla <strong>${data.nombre}</strong>?`;
      this.$refs.modalDuplicar.open();
    },
    async confirmar_duplicar() {
      let datos = {
        plantilla: this.id_duplicar,
      };
      const data = await this.$store.dispatch("duplicarPlantilla", datos);
      if (_.has(data, "STATUS_CODE") && data.STATUS_CODE == 1) {
        const resp = await this.$store.dispatch("fetchPlantillas");
        if (_.has(resp, "STATUS_CODE") && resp.STATUS_CODE == 1) {
          this.$refs.modalDuplicar.open();
          this.id_duplicar = "";
          this.mensaje_duplicar = "";
        }
      }
    },
    async eliminar() {
      const data = await this.$store.dispatch(
        "eliminarPlantilla",
        this.id_duplicar
      );
      if (_.has(data, "STATUS_CODE") && data.STATUS_CODE == 1) {
        const resp = await this.$store.dispatch("fetchPlantillas");
        if (_.has(resp, "STATUS_CODE") && resp.STATUS_CODE == 1) {
          this.$refs.modalEliminar.open();
          this.id_duplicar = "";
        }
      }
    },
  },
};
</script>